import React, { useState, useEffect, useRef } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCTS_API, PRODUCTSIZE_API } from "../../../../../Utilities/APIs/APIs";
import Barcode from "react-barcode";
import "./ProductLabelPrinter.css";

function ProductLabelPrinter() {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSizes, setProductSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [labelQuantity, setLabelQuantity] = useState(1);
  const [labels, setLabels] = useState([]);
  const axiosInstance = useAxiosInstance();
  const labelsRef = useRef();

  // Fetch products based on search input
  useEffect(() => {
    async function fetchProducts() {
      const res = await axiosInstance.get(`${PRODUCTS_API}?search=${search}`);
      setProducts(res?.data?.products || []);
    }
    if (search) fetchProducts();
  }, [search, axiosInstance]);

  // Fetch sizes for the selected product
  useEffect(() => {
    async function fetchSizes() {
      if (selectedProduct) {
        const res = await axiosInstance.get(
          PRODUCTSIZE_API + "getProductSizesByProduct/" + selectedProduct._id
        );
        setProductSizes(res?.data || []);
        setSelectedSize(null); // Reset size when a new product is selected
      }
    }
    fetchSizes();
  }, [selectedProduct, axiosInstance]);

  // Generate labels
  const handleGenerateLabels = () => {
    if (selectedProduct && selectedSize && labelQuantity > 0) {
      const newLabels = Array.from({ length: labelQuantity }, (_, index) => ({
        productName: selectedProduct.name,
        sku: selectedProduct.squId, // Assuming SKU is available in the product object
        size: selectedSize.size.name,
        price: selectedProduct.price,
        currentPrice: selectedProduct.currentPrice,
        barcode: `${selectedProduct.squId}-${selectedSize.size.name}-${index + 1}`, // Assuming product has a `barcode` field
      }));
      setLabels(newLabels);
    }
  };

  // Print labels
  const handlePrint = () => {
    const printContent = labelsRef.current;
    const newWindow = window.open("", "_blank");
  
    newWindow.document.write(`
      <html>
        <head>
          <title>Print Labels</title>
          <style>
            @page {
              size: 4in 6in;  /* Explicit 3x4 inches paper size */
              margin: 0;
            }
  
            body {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              font-family: Roboto, Arial, sans-serif;
            }
  
            .label {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              text-align: center;
              page-break-after: always;
              padding: 0;
              margin: 0;
              font-family: Roboto, Arial, sans-serif;
            }
  
            /* Styling for product name with wrapping */
            .label h2 {
              font-size: 30px;  
              margin: 0;
              padding: 0;
              font-weight: bold;
              word-wrap: break-word; 
              margin-bottom: 20px;  
              margin-top: 25px;
              width: 100%;  
              font-family: Roboto, Arial, sans-serif;
            }
  
            /* Styling for SKU and other product info */
            .label p {
              margin: 0;
              padding: 0;
              font-size: 26px;  
              font-weight: bold;
              margin-bottom: 10px; 
              font-family: Roboto, Arial, sans-serif;
            }
  
            .barcode {
              margin-top: auto;  
              font-size: 24px;
              margin-bottom: 150px;  
              width: 100%;
              font-family: Roboto, Arial, sans-serif;
              font-weight: bold;
            }
  
            @media print {
              .label {
                page-break-before: always;
              }
              body {
                display: block;
                height: auto;
              }
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${Array.from(printContent.children)
            .map(
              (child) =>
                `<div class="label">${child.innerHTML}</div>`
            )
            .join("")}
        </body>
      </html>
    `);
    newWindow.document.close();
  };
  
  
  
   
  
  return (
    <div className="product-label-printer">
      <h1>Product Label Genarater</h1>

      {/* Search Products */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search the product and select the size you want to genarate label"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {/* Select Product */}
      <div className="products-list">
        {products.map((product) => (
          <div
            key={product._id}
            onClick={() => setSelectedProduct(product)}
            className={`product-item ${selectedProduct?._id === product._id ? "selected" : ""}`}
          >
            {product.name}
          </div>
        ))}
      </div>

      {/* Select Size */}
      {selectedProduct && (
        <div className="sizes-list">
          <h3>Available Sizes</h3>
          {productSizes.map((size) => (
            <div
              key={size._id}
              onClick={() => setSelectedSize(size)}
              className={`size-item ${selectedSize?._id === size._id ? "selected" : ""}`}
            >
              {size.size.name} (Quantity: {size.quantity})
            </div>
          ))}
        </div>
      )}

      {/* Input Label Quantity */}
      {selectedSize && (
        <div className="label-quantity">
          <label>
            Enter Label Quantity:{" "}
            <input
              type="number"
              value={labelQuantity}
              onChange={(e) => setLabelQuantity(Math.max(1, parseInt(e.target.value) || 1))}
            />
          </label>
        </div>
      )}

      {/* Generate Labels */}
      {selectedSize && (
        <button onClick={handleGenerateLabels} className="generate-button">
          Generate Labels
        </button>
      )}

      {/* Display Labels */}
      {labels.length > 0 && (
        <div className="labels-preview">
          {/* <h3 style={{fontSize:"18px"}}>Labels</h3> */}
          <div className="labels-container" ref={labelsRef}>
            {labels.map((label, index) => (
              <div key={index} className="label">
                <h2>{label.productName}</h2>
                <p>SKU: {label.sku}</p>
                <p>SIZE: {label.size}</p>
                <p>
                  PRICE:{" "}
                  {label.price !== label.currentPrice ? (
                    <>
                      <span style={{ textDecoration: "line-through" }}>{label.price}</span>
                      &nbsp; <span>{label.currentPrice}</span>
                    </>
                  ) : (
                    label.price
                  )}
                </p>
                <div className="barcode">
                  <Barcode value={label.barcode || "000000000"} format="CODE128" />
                </div>
              </div>
            ))}
          </div>
          <button onClick={handlePrint} className="print-button">
            Print Labels
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductLabelPrinter;
