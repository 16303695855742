import { Link } from "react-router-dom";
import "./OrderInvoice.css";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  ORDERS_API,
  PRODUCTSIZE_API,
  PRODUCTS_API,
} from "../../../../Utilities/APIs/APIs";

function OrderInvoiceArch({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const [order, setOrder] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState(null);

  // const [items, setItems] = useState(null);
  // const [productSizes, setProductSizes] = useState(null);

  useEffect(() => {
    async function fetchAndSetOrder() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "getSingleOrder/" + targetID
      );
      setOrder(data[0]);
    }

    fetchAndSetOrder();
  }, [targetID, axiosInstance]);

  useEffect(() => {
    async function fetchAndSetOrderItems() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "orderItemsFromOrder/" + targetID
      );
      setOrderItems(data.orderItems);
    }
    fetchAndSetOrderItems();

  }, [axiosInstance, targetID]);

  useEffect(() => {
    async function fetchInvoiceNo() {
      try {
        const { data } = await axiosInstance.get(
          ORDERS_API + "getArchSingleOrder/" + targetID
        );
        setInvoiceNo(data.invoiceNo); // Extract ONLY invoiceNo
      } catch (error) {
        console.error("Error fetching archived order:", error);
        setInvoiceNo(null); // Set to null on error
      }
    }

    fetchInvoiceNo();
  }, [targetID, axiosInstance, setInvoiceNo]); // Correct dependencies


  const conponentPDF = useRef();

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "invoice",
    // onAfterPrint:()=>alert("Data saved in PDF")
  });

  const discountedPrice =
    (order?.orderSubtotal - order?.promoDiscountAmount) * 0.10;
  const roundedDiscountedPrice = Math.round(discountedPrice);

  return (
    <div>
      <button className="submit" onClick={generatePDF}>
        Print Invoice
      </button>
      <div
        ref={conponentPDF}
        className="container invoice_padding"
        style={{
          padding: "15px 20px 20px 20px",
          marginBottom: "20px",
          backgroundColor: "#FFF",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h5 style={{ fontWeight: "bold", fontSize: "14px" }}>BIN NUMBER: 0034293920101 </h5>
        </div>{" "}
        <br />
        <h4 style={{ float: "right", fontWeight: "bold", fontSize: "14px" }}>Mushok-6.3</h4>
        <div className="pull-right"></div>
        <div style={{ addingBottom: "30px", overflow: "hidden" }}>
          <div
            style={{ display: "inlineBlock", width: "33.33%", float: "left" }}
          >
            <div style={{ overflow: "hidden" }}>
              <p style={{ margin: "0", color: "#414040" }}>1</p>
              <hr
                style={{
                  width: "75%",
                  float: "left",
                  borderBottom: "1px solid #ccc",
                }}
              />
            </div>
            <p
              style={{
                margin: "0",
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "19px",
                paddingBottom: "3px",
                color: "#333333",
              }}
            >
              ORDER NUMBER
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {invoiceNo && invoiceNo}
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              Date: {new Date(order?.createdAt).toLocaleDateString()}
            </p>
          </div>

          <div
            style={{ display: "inlineBlock", width: "33.33%", float: "left" }}
          >
            <div style={{ overflow: "hidden" }}>
              <p style={{ margin: "0", color: "#414040" }}>2</p>
              <hr
                style={{
                  width: "75%",
                  float: "left",
                  borderBottom: "1px solid #ccc",
                }}
              />
            </div>
            <p
              style={{
                margin: "0",
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "19px",
                paddingBottom: "3px",
                color: "#333333",
              }}
            >
              ADDRESS
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {order?.shippingName}
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {order?.shippingAddress},{order?.customerArea}
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {order?.customerDistrict}, Bangladesh
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {order?.orderCustomerEmail}
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {order?.shippingPhone}
            </p>
          </div>
          <div
            style={{ display: "inlineBlock", width: "33.33%", float: "left" }}
          >
            <div style={{ overflow: "hidden" }}>
              <p style={{ margin: "0", color: "#414040" }}>3</p>
              <hr
                style={{
                  width: "75%",
                  float: "left",
                  borderBottom: "1px solid #ccc",
                }}
              />
            </div>
            <p
              style={{
                margin: "0",
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "19px",
                paddingBottom: "3px",
                color: "#333333",
              }}
            >
              ESTIMATED DELIVERY
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#484748",
              }}
            >
              {new Date(
                new Date(order?.createdAt).setDate(
                  new Date(order?.createdAt).getDate() + 3
                )
              ).toLocaleDateString()}
            </p>

            <p
              style={{
                margin: "0",
                fontSize: "11px",
                lineHeight: "16px",
                fontWeight: "bold",
                color: "#f26822",
              }}
            >
              {order?.gifted === "true" ? "Gift" : ""}
            </p>
          </div>
        </div>
        <div style={{ overflow: "hidden", paddingTop: "20px" }}>
          <div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tr>
                <th
                  style={{
                    width: "40%",
                    textAlign: "left",
                    borderBottom: "1px solid #333333",
                    padding: "10px 3px 10px 0",
                    color: "#333333",
                    fontSize: "12px"
                  }}
                >
                  Item
                </th>
                {/* <th
                  style={{
                    width: "10%",
                    textAlign: "left",
                    borderBottom: "1px solid #333333",
                    padding: "10px 3px 10px 0",
                    color: "#333333",
                  }}
                >
                  Status
                </th> */}
                <th
                  style={{
                    width: "20%",
                    textAlign: "left",
                    borderBottom: "1px solid #333333",
                    padding: "10px 3px 10px 0",
                    color: "#333333",
                    fontSize: "12px"
                  }}
                >
                  Qty
                </th>
                <th
                  style={{
                    width: "20%",
                    textAlign: "left",
                    borderBottom: "1px solid #333333",
                    padding: "10px 3px 10px 0",
                    color: "#333333",
                    fontSize: "12px"
                  }}
                >
                  Size
                </th>
                {order?.gifted === "true" ? (
                  ""
                ) : (
                  <th
                    style={{
                      width: "10%",
                      textAlign: "right",
                      borderBottom: "1px solid #333333",
                      padding: "10px 3px 10px 0",
                      color: "#333333",
                      fontSize: "12px"
                    }}
                  >
                    Price
                  </th>
                )}
              </tr>
              {orderItems?.length > 0 &&
                orderItems?.map((orderItem) => (
                  <tr
                    style={{ fontSize: "11px", lineHeight: "16px" }}
                    key={orderItem._id}
                  >
                    <td
                      style={{
                        borderBottom: "1px solid #333333",
                        padding: "5px 3px 15px 0",
                        color: "#040406"
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>{orderItem?.item?.name}</span><br/>
                      <span style={{fontSize: "9px"}}>{orderItem?.item?.squId}</span>
                    </td>
                    {/* <td
                    style={{
                      borderBottom: "1px solid #333333",
                      padding: "5px 3px 15px 0",
                      color: "#686868",
                    }}
                  >
                    Pending
                  </td> */}
                    <td
                      style={{
                        borderBottom: "1px solid #333333",
                        padding: "5px 3px 15px 0",
                        color: "#686868",
                      }}
                    >
                      {orderItem?.quantity}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #333333",
                        padding: "5px 3px 15px 0",
                        color: "#686868",
                      }}
                    >
                      {orderItem?.size?.name}
                      {/* S (UP/Down) */}
                    </td>

                    {order?.gifted === "true" ? (
                      ""
                    ) : (
                      <td
                        style={{
                          borderBottom: "1px solid #333333",
                          padding: "5px 3px 15px 0",
                          color: "#686868",
                          textAlign: "right",
                        }}
                      >
                        {Math.round(orderItem?.totalPrice)}
                      </td>
                    )}
                  </tr>
                ))}
              {/* <tr style={{ fontSize: "11px", lineHeight: "16px" }}>
                <td
                  style={{
                    borderBottom: "1px solid #333333",
                    padding: "5px 3px 15px 0",
                    color: "#686868",
                  }}
                >
                  product_name
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #333333",
                    padding: "5px 3px 15px 0",
                    color: "#686868",
                  }}
                >
                  Pending
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #333333",
                    padding: "5px 3px 15px 0",
                    color: "#686868",
                  }}
                >
                  1
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #333333",
                    padding: "5px 3px 15px 0",
                    color: "#686868",
                  }}
                >
                  {" "}
                  S (UP/Down)
                </td>

                <td
                  style={{
                    borderBottom: "1px solid #333333",
                    padding: "5px 3px 15px 0",
                    color: "#686868",
                    textAlign: "right",
                  }}
                >
                  total_price
                </td>
              </tr> */}

              {order?.gifted === "true" ? (
                <tr>
                <td style={{  }}></td>
                <td
                  colspan="3"
                  style={{
                    width: "100%",
                    
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      fontSize: "12px",
                      lineHeight: "16px",
                    }}
                  >
                    <tr>
                    <td style={{
                          width: "65%",
                          color: order?.shippingMethod === "express" ? "red" : "#686868",
                          fontWeight: "700"
                        }}
                      >
                        Delivery ({order?.shippingMethod})
                      </td>
                      
                    </tr>
                    
                  </table>
                </td>
              </tr>
              ) : (
                <>
                  <tr>
                    <td style={{ borderBottom: "1px solid #333333" }}></td>
                    <td
                      colspan="4"
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #333333",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          fontSize: "11px",
                          lineHeight: "16px",
                        }}
                      >
                        <tr>
                          <td style={{ width: "65%", color: "#686868" }}>
                            Subtotal
                          </td>
                          <td
                            style={{
                              width: "35%",
                              textAlign: "right",
                              color: "#686868",
                            }}
                          >
                            {Math.round(order?.orderSubtotal)}
                          </td>
                        </tr>
                        <tr>
                        <td style={{
                              width: "65%",
                              color: order?.shippingMethod === "express" ? "red" : "#686868",
                            }}
                          >
                            Delivery ({order?.shippingMethod})
                          </td>
                          <td
                            style={{
                              width: "35%",
                              textAlign: "right",
                              color: "#686868",
                            }}
                          >
                            {order?.shippingRate }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "65%", color: "#686868" }}>
                            Discount
                          </td>
                          <td
                            style={{
                              width: "35%",
                              textAlign: "right",
                              color: "#686868",
                            }}
                          >
                            {order?.promoDiscountAmount
                              ? order?.promoDiscountAmount
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "65%",
                              fontWeight: "700",
                              paddingTop: "10px",
                              color: "#333333",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              width: "35%",
                              textAlign: "right",
                              fontWQeight: "700",
                              paddingTop: "10px",
                              color: "#333333",
                            }}
                          >
                            {order?.orderTotal}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="4">
                      <table
                        style={{
                          width: "100%",
                          fontSize: "11px",
                          lineHeight: "16px",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "65%",
                              paddingTop: "10px",
                              color: "#686868",
                            }}
                          >
                            VAT (
                            <span style={{ fontWeight: "bold" }}>10%</span>)
                            Inclusive
                          </td>
                          <td
                            style={{
                              width: "35%",
                              textAlign: "right",
                              paddingTop: "10px",
                              color: "#686868",
                            }}
                          >
                            {roundedDiscountedPrice}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>
        <p
          style={{
            paddingTop: "10px",
            marginBottom: "-11px",
            fontSize: "12px",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          Payment Method:{" "}
          <span
              style={{
                color: order?.paymentMethod === "bkash" || order?.paymentMethod === "SSLCommerz" ? "red" : "#686868",
                fontSize: order?.paymentMethod === "bkash" || order?.paymentMethod === "SSLCommerz" ? "12px" : "12px",
              }}
            >
              {order?.paymentMethod === "cash"
                ? "Cash on Delivery"
                : order?.paymentMethod === "bkash"
                ? "Paid by Bkash"
                : order?.paymentMethod === "SSLCommerz"
                ? "Paid by SSLCOMMERZ"
                : order?.paymentMethod}
          </span>
        </p>
        <p
          style={{
            paddingTop: "33px",
            marginBottom: "-11px",
            fontSize: "12px",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          Additional Instructions:{" "}
          <span style={{ color: "red", fontSize: "12px" }}>
            {order?.additionalInstructions}
          </span>
        </p>
        <div
          style={{
            fontSize: "11px",
            lineHeight: "11px",
            overflow: "hidden",
            paddingTop: "40px",
            color: "#686868",
          }}
        >
          <p>Changed your mind?</p>
          <p>
            Don&#39;t Worry! You can easily return or exchange your items
            (within 7 days) of delivery.
          </p>
          <p>
            Visit our{" "}
            <Link
              style={{
                textDecoration: "none",
                color: "#686868",
                fontWeight: "600",
              }}
            >
              {" "}
              Returns Policy
            </Link>{" "}
            page on website, or{" "}
            <Link
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#686868",
                fontWeight: "600",
              }}
            >
              Contact Us
            </Link>{" "}
            for more information.
            <br />
            <br />
            Thank you for shopping on Strides Co!
          </p>
        </div>
        <div
          style={{
            overflow: "hidden",
            paddingTop: "30px",
            fontSize: "11px",
            lineHeight: "11px",
            color: "#686868",
          }}
        >
          <p>Warm Regards</p>
          <p style={{ fontWeight: "bold", color: "#323232" }}>
            The StridesCo Team
          </p>
          <p>
            <Link
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                color: "#f26822",
                textDecoration: "none",
              }}
            >
              www.stridesco.com
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrderInvoiceArch;