import { Link } from 'react-router-dom';
import blacklogo from '../../../../Assets/Images/blacklogo.png'
import orderconfirmation from '../../../../Assets/Images/orderconfirmation.png'


function OrderMail() {
  return (

<div style={{ width: "600px" , margin:"0 auto", padding: "30px", border: "3px solid #f26822", backgroundColor: "#fff" }}>
  <div>
    <div style={{ overflow: "hidden", paddingBottom: "70px" }}>
      <div style={{ display: "inlineBlock", width: "50%", float: "left" }}>
        <img style={{width: "170px"}} src={orderconfirmation} alt="order-confirmation" />
      </div>
      <div style={{ display: "inlineBlock", width: "50%", float: "left", textAlign: "right", paddingBottom: "30px" }}> <img style={{width: "170px"}} src={blacklogo} alt="logo" /></div>
    </div>
    <div style={{fontSize: "12px", color: "#686969", lineHeight: "13px", paddingBottom: "50px" }}>
      <p>Hi customer_name,</p>
      <p>Thank you for shopping on StridesCo! Hope you enjoyed shopping with us.</p>
      <p>Your order is now being processed.</p>
    </div>
    </div>

    <div style={{ addingBottom: "30px" , overflow: "hidden" }}>
      <div style= {{ display: "inlineBlock", width: "33.33%", float: "left" }}>
        <div style= {{ overflow: "hidden" }}>
          <p style= {{ margin: "0" , color: "#414040" }} >1</p>
          <hr style= {{ width: "75%", float: "left", borderBottom: "1px solid #ccc" }} />
        </div>
        <p style= {{ margin: "0", fontSize: "12px", fontWeight: "700", lineHeight: "19px", paddingBottom: "3px", color: "#333333" }}>ORDER NUMBER</p>
        <p style= {{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }}>SCO(invoice_no)</p>
        <p style= {{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }}>Date:(order_added_date)</p>
      </div>

      <div style={{ display: "inlineBlock", width: "33.33%", float: "left" }}>
        <div style={{ overflow: "hidden" }}>
          <p style={{ margin: "0", color: "#414040" }}>2</p>
          <hr style={{ width: "75%", float: "left", borderBottom: "1px solid #ccc" }}/>
        </div>
        <p style={{ margin: "0", fontSize: "12px", fontWeight: "700", lineHeight: "19px", paddingBottom: "3px", color:"#333333" }}>ADDRESS</p>
        <p style={{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }}>shipping_name</p>
        <p style={{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }} >shipping_address</p>
        <p style={{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }} >Bangladesh</p>
        <p style={{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }} >shipping_phone</p>
      </div>
      <div style={{ display: "inlineBlock", width: "33.33%", float: "left" }} >
        <div style={{ overflow: "hidden" }} >
          <p style={{ margin: "0", color: "#414040" }} >3</p>
          <hr style={{ width: "75%", float: "left", borderBottom: "1px solid #ccc" }} />
        </div>
        <p style={{ margin: "0",fontSize: "12px", fontWeight: "700", lineHeight: "19px", paddingBottom: "3px", color: "#333333" }}>ESTIMATED DELIVERY</p>
        <p style={{ margin: "0", fontSize: "11px", lineHeight: "16px", color: "#484748" }} >deliverydate_end</p>
        
        {/* @if($order_details->gifted ==1) */}
        <p style= {{ margin: "0", fontSize: "11px", lineHeight: "16px", fontWeight: "bold", color: "#f26822" }}>Gift</p>
        {/* @endif */}
        
      </div>
    </div>
    <div style={{ overflow: "hidden", paddingTop: "20px" }}>
      <div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tr>
            <th style={{ width: "40%", textAlign: "left", borderBottom: "1px solid #333333",padding: "10px 3px 10px 0",color: "#333333" }}>Item</th>
            <th style={{ width: "20%", textAlign: "left", borderBottom: "1px solid #333333",padding: "10px 3px 10px 0",color: "#333333" }} >Qty</th>
            <th style={{ width: "20%", textAlign: "left", borderBottom: "1px solid #333333",padding: "10px 3px 10px 0",color: "#333333" }} >Size</th>
            {/* @if($order_details->gifted !=1)   */}
            <th style={{ width: "10%", textAlign: "right", borderBottom: "1px solid #333333",padding: "10px 3px 10px 0",color: "#333333" }} >Price</th>
            {/* @endif */}
          </tr>
          {/* <?php $re = sizeof($order_product); $i=1;?>
          @foreach($order_product as $odrproduct)
          <?php $brs = '1px'; if($re==$i) $brs = '2px'; ?> */}
          <tr style={{ fontSize: "11px", lineHeight: "16px" }}>
            <td style={{ borderBottom: "1px solid #333333", padding: "5px 3px 15px 0", color: "#686868" }}>product_name</td>
            <td style={{ borderBottom: "1px solid #333333", padding: "5px 3px 15px 0", color: "#686868" }} >1</td>
            <td style={{ borderBottom: "1px solid #333333", padding: "5px 3px 15px 0", color: "#686868" }}> S (UP/Down)</td>
              {/* <?php if( $odrproduct->alter_sizeup > 0 and $odrproduct->alter_sizedown > 0 ){echo '( Up / Down )';} elseif( $odrproduct->alter_sizeup < 1 and $odrproduct->alter_sizedown > 0 ){echo '( Down )';}elseif( $odrproduct->alter_sizeup > 0 and $odrproduct->alter_sizedown < 1 ){echo '( Up )';} ?></td>
            
             @if($order_details->gifted !=1)    */}
            
            <td style={{ borderBottom: "1px solid #333333", padding: "5px 3px 15px 0", color: "#686868", textAlign: "right" }} >total_price</td>
            {/* @endif */}
          </tr>
          {/* <?php $i++;?>
          @endforeach */}
          <tr>
            {/* @if($order_details->gifted !=1) */}
            <td style={{ borderBottom: "1px solid #333333" }} ></td>
            <td colspan="4" style= {{width: "100%", borderBottom: "1px solid #333333" }} ><table style={{ width: "100%", fontSize: "11px", lineHeight: "16px" }} >
                <tr>
                  <td style={{ width: "65%", color: "#686868" }}>Subtotal</td>
                  <td style={{ width: "35%", textAlign: "right", color: "#686868" }}>order_subtotal</td>
                </tr>
                <tr>
                    {/* <?php
                    if ($order_details->shipping_method == 'Flat Shipping Rate'){
                        $order_details->shipping_method =  'Standard';
                    }
                    ?> */}
                  <td style={{ width: "65%", color: "#686868" }} >Delivery (shipping_method)</td>

                  <td style={{ width: "35%", textAlign: "right", color: "#686868" }}>shipping_rate</td>
                </tr>
                <tr>
                  <td style={{ width: "65%", color: "#686868" }} >Discount</td>
                  <td style={{ width: "35%", textAlign: "right", color: "#686868" }} >promo_discount_amount</td>
                </tr>
                <tr>
                  <td style= {{ width: "65%", fontWeight: "700", paddingTop: "10px", color: "#333333" }}>Total</td>
                  <td style={{ width: "35%", textAlign: "right", fontWQeight: "700", paddingTop: "10px", color: "#333333" }}>order_total</td>
                </tr>
                
                {/* @endif */}
              </table></td>
          </tr>
          
            <tr>
            <td>&nbsp;</td>
            <td colspan="4">
              <table style={{ width: "100%", fontSize: "11px", lineHeight: "16px" }}>
                <tr>
                  <td style={{ width: "65%", paddingTop: "10px", color: "#686868" }}>VAT (<span style={{ fontWeight: "bold" }}>10%</span>) Inclusive</td>
                  <td style={{ width: "35%", textAlign: "right", paddingTop: "10px", color: "#686868" }}>10% Discount</td>
                </tr>                
              </table> 
            
            </td>
          </tr>
          
        </table>
      </div>
    </div>

    
	<p style={{ paddingTop: "10px", marginBottom: "-11px", fontSize: "12px", fontWeight: "700",color: "#333333" }}>Payment Method: <span style={{ color: "#686868", fontSize: "11px" }}> payment_method</span></p>
	
	{/* <?php if(!empty($order_details->additional_instructions)){ ?> */}
	  <p style={{ paddingTop: "10px", marginBottom: "-11px", fontSize: "12px", fontWeight: "700", color: "#333333" }}>Additional Instructions: <span style={{ color: "#686868", fontSize: "11px" }}> additional_instructions;</span></p>
	{/* <?php } ?> */}
    <div style={{ fontSize: "11px", lineHeight: "11px", overflow: "hidden", paddingTop: "10px", color: "#686868" }}>
      <p>Changed your mind?</p>
      <p>Don&#39;t Worry! You can easily return or exchange your items within 7 days of delivery.</p>
      <p>Visit our <a href="{{url('/help')}}" target="_blank" style={{ textDecoration: "none", color: "#686868", fontWeight: "600" }}> Returns Policy</a> page on website, or <a href="{{url('/help')}}" target="_blank" style={{ textDecoration: "none", color: "#686868", fontWeight: "600" }}>Contact Us</a> for more information.<br />
        <br />
        We will be in touch as soon as your order has been dispatched.</p>
    </div>
    <div style={{ overflow: "hidden", paddingTop: "30px", fontSize: "11px", lineHeight: "11px", color: "#686868" }}>
      <p>Warm Regards</p>
      <p style={{ fontWeight: "bold", color: "#323232" }} >The StridesCo Team</p>
      <p><a style={{ fontWeight: "bold",fontSize: "11px",color: "#f26822", textDecoration: "none" }} href="{{url('/')}}">www.stridesco.com</a></p>
    </div>
    <div style={{ overflow: "hidden", paddingTop: "110px" }}>
      <div style={{ display: "inlineBlock", width: "35%", float:"left", paddingTop: "13px" }}>
        <p style={{ fontWeight: "bold", fontSize: "11px", margin: "0", lineHeight: "20px", color: "#414040" }}>STRIDES<span style={{ color: "#f26822" }}>CO</span></p>
        <p style={{ fontWeight: "bold",fontSize: "8px", margin:"0", lineHeight: "14px", color:"#76787A" }}><span style={{ color: "#EF6632" }}>T: </span> +880 9678800553 <span style={{ color: "#EF6632" }}> W: </span><a style={{color: "#76787A", textDecoration: "none" }} href="{{url('/')}}">www.stridesco.com</a></p>
      </div>
      <div style={{ display: "inlineBlock", width: "65%", float: "left", textAlign: "right", color:"#76787A" }}>
        <p style={{fontWeight: "bold", fontSize: "12px", clear: "both", paddingTop: "12px", lineHeight: "14px", color:"#41403F" }}><a href="{{url('/women')}}" target="_blank" style={{ textDecoration: "none", display: "inlineBlock", paddingRight: "40px" }}>WOMEN</a><a style={{ color: "#41403F", textDecoration: "none"}} href="{{url('/men')}}" target="_blank" >MEN</a></p>
      </div>
    </div>
  </div>

  )
}

export default OrderMail