import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiListSettingsLine } from "react-icons/ri";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import Pagination from "../../../Partials/Elements/pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import { takaFormatter } from "../../../../Utilities/Formatter";
import ViewOrder from "../OrderScreens/ViewOrder";
import OrderInvoice from "../../../Partials/Elements/OrderInvoice/OrderInvoice";

function ArchSalesReport() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showViewSection, setShowViewSection] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(40);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [oneday, setOneday] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [targetID, setTargetID] = useState(null);

  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    setStartDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
    setEndDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
  }, [])

  useEffect(() => {
    async function fetchAndSetProducts() {
      try {
        const res = await axiosInstance.get(
          `${ORDERS_API}archOrdersSalesReport?startDate=${startDate}&endDate=${endDate}&oneday=${oneday}&page=${currentPage}&limit=${limit}`
        );

        setProducts(res?.data?.orders || []);
        setTotalItems(res?.data?.totalItems || 0);

        const formatNumber = new Intl.NumberFormat("en-IN").format(
          parseInt(res?.data?.totalOrderAmount || 0)
        );

        setTotalOrderPrice(formatNumber);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      }
    }
    fetchAndSetProducts();
  }, [axiosInstance, currentPage, startDate, endDate, limit, oneday]);

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Orders ({totalItems} in total)</h1>
        <DateInput
          label={"Start Date"}
          value={startDate}
          setState={setStartDate}
          format="yyyy-MM-dd"
        />
        <DateInput
          label={"End Date"}
          value={endDate}
          setState={setEndDate}
          format="yyyy-MM-dd"
        />
        <DateInput
          label={"One Day"}
          value={oneday}
          setState={setOneday}
          format="yyyy-MM-dd"
        />
        <h1 className="screen_heading">
          Total Order Price {totalOrderPrice} TK
        </h1>
      </div>

      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Invoice No" />
          <CRUDth th="Date" />
          <CRUDth th="Name" />
          <CRUDth th="Phone" />
          <CRUDth th="Order Total" />
          <CRUDth th="Delivery Media" />
          {/* <CRUDth th="Actions" /> */}
        </CRUDTableHeader>

        <tbody>
          {products.length > 0 ? (
            products.map((order) => (
              <CRUDTableRow key={order._id}>
                <ShortTextCell text={order?.invoiceNo} />
                <ShortTextCell text={new Date(order.orderID?.createdAt).toDateString()} />
                <ShortTextCell text={order.orderID?.shippingName} />
                <ShortTextCell text={order.orderID?.shippingPhone} />
                <ShortTextCell text={takaFormatter(order.orderID?.orderTotal)} />
                <ShortTextCell text={order.orderID?.shippingMediaType} />

                {/* <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={order?.orderID?._id}
                        setTargetID={setTargetID}
                        tooltipContent={"View Product Details"}
                        tooltipName={"view"}
                      />
                      <CRUDButton
                        handleClick={() =>
                          navigate("/main/order_items_from_order/" + order.orderID._id)
                        }
                        tooltip={"Order Items From Order"}
                      >
                        <RiListSettingsLine />
                      </CRUDButton>
                    </div>
                  </div>
                </td> */}
              </CRUDTableRow>
            ))
          ) : (
            <tr>
            </tr>
          )}
        </tbody>

        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Order Details`}
          setShowModalContent={setShowViewSection}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )}
      {showInvoice && (
        <Modal
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoice}
        >
          <OrderInvoice targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default ArchSalesReport;